<template>
  <div class="edit-profile">
    <h1 class="title">{{ $t('editProfile.title') }}</h1>

    <form @submit.prevent="update">
      <input-group
              type="text"
              :label="$t('editProfile.form.name')"
              v-model="user.name"
              name="name"
              required
      />

      <color-picker
              :label="$t('editProfile.form.color')"
              v-model="user.color"
              required
      />

      <button type="submit" class="button is-primary">{{ $t('common.submit') }}</button>
    </form>

    <hr>

    <change-password />
  </div>
</template>

<script>
import InputGroup from '../basic/InputGroup'
import { mapState } from 'vuex'
import ColorPicker from '../basic/ColorPicker'
import ChangePassword from './ChangePassword'

export default {
  name: 'edit-profile',
  components: { ChangePassword, ColorPicker, InputGroup },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  methods: {
    async update () {
      await this.$store.dispatch('updateProfile', this.user)

      this.$store.commit('showAlertBar', { text: this.$t('editProfile.updatedSuccessMessage'), type: 'success' })

      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>
