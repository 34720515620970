<template>
  <div class="color-picker mb-3">
    <label v-if="label" class="label">{{ label }} <span v-if="required">*</span></label>
    <div class="color-group">
      <button
              :class="{'active': key === val}"
              v-for="(color, key) in colors"
              :key="key"
              @click.prevent="val = key"
              :style="{'background-color': color.standard, 'border-color': color.lighter}"
      ></button>
    </div>
  </div>
</template>

<script>
import { COLORS } from '../../constants'

export default {
  name: 'color-picker',
  props: {
    value: {
      type: String,
      default: '#598691'
    },
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      colors: COLORS
    }
  },
  computed: {
    val: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .color-group {
    background: white;
    border-radius: 2rem;
    padding: 0.25rem;
    width: inherit;
    display: inline-block;
    line-height: 1;
  }

  button {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    margin-right: 0.25rem;
    position: relative;
    border: 0.15rem solid;

    &:last-child {
      margin-right: 0;
    }

    &:focus {
      outline: none;
    }

    &.active {
      &::after {
        display: block;
        width: calc(100% - 0.5rem);
        height: calc(100% - 0.5rem);
        content: "";
        position: absolute;
        border-radius: 100%;
        left: 0.25rem;
        top: 0.25rem;
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
</style>
